import { useState, Suspense, useEffect, lazy } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Login from "./Pages/Login";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Components/Navbar";
import Loadingscreen from "./Components/Loadingscreen";
import DesignerAppBar from "./Pages/DesignerPages/DesignerAppBar";
import UserNav from "./Components/UserNav";
const AdminHome = lazy(() => import("./Pages/AdminPages/AdminHome"));
const UserCreation = lazy(() => import("./Pages/AdminPages/UserCreation"));
const UserList = lazy(() => import("./Pages/AdminPages/UserList"));
const EditUsers = lazy(() => import("./Pages/AdminPages/EditUsers"));
const DesignerHome = lazy(() => import("./Pages/DesignerPages/DesignerHome"));
const UploadImage = lazy(() => import("./Pages/DesignerPages/UploadImage"));
const UserHome = lazy(() => import("./Pages/UserPage/UserHome"));
//master
const ItemMaster = lazy(() => import("./Pages/UserPage/Master/ItemMaster"));
const Mainledger = lazy(() => import("./Pages/UserPage/Master/Mainledger"));
const Subledger = lazy(() => import("./Pages/UserPage/Master/Subledger"));
//quality master
const QualityMaster = lazy(() =>
  import("./Pages/UserPage/QualityMaster/QualityMaster")
);
const SamplePrint = lazy(() =>
  import("./Pages/UserPage/QualityMaster/SamplePrint")
);
//order confirm
const OcEntry = lazy(() => import("./Pages/UserPage/OrderConfirm/OcEntry"));
const Revision = lazy(() => import("./Pages/UserPage/OrderConfirm/Revision"));
//purchase
const YarnPurchaseEntry = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/YarnPurchaseEntry")
);
const FabricPurchaseEntry = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/FabricPurchaseEntry")
);
const JobberOrd = lazy(() => import("./Pages/UserPage/JobberOrder/JobberOrd"));
const Grn = lazy(() => import("./Pages/UserPage/PurchaseOrder/Grn"));
const ProformaInvoice = lazy(() =>
  import("./Pages/UserPage/ProformaInvoice/ProformaInvoice")
);
const CourierInvoice = lazy(() =>
  import("./Pages/UserPage/Courier/CourierInvoice")
);
const DyeChemiclePur = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemiclePur")
);
const SamplePur = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/SamplePurchase")
);
const DyeChemGrn = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemGrn")
);
const DyeChemIssue = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemIssue")
);
const DyeChemReturn = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemReturn")
);
const DyeChemRecipie = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemRecipie")
);
const YarnGrn = lazy(() => import("./Pages/UserPage/PurchaseOrder/YarnGrn"));
const SampleGrn = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/SampleGrn")
);
const CutIntoPieces = lazy(() =>
  import("./Pages/UserPage/Process/CutIntoPieces")
);
const FabricProcessOrder = lazy(() =>
  import("./Pages/UserPage/Process/FabricProcessOrder")
);
const FabricProcessInward = lazy(() =>
  import("./Pages/UserPage/Process/FabricProcessInward")
);
const QualityControl = lazy(() =>
  import("./Pages/UserPage/Process/QualityControl")
);
const Folding = lazy(() => import("./Pages/UserPage/Process/Folding"));
const JobberOutWard = lazy(() =>
  import("./Pages/UserPage/JobberOrder/JobberOutWard")
);
const JobberGrn = lazy(() => import("./Pages/UserPage/JobberOrder/JobberGrn"));
const ITMoutward = lazy(() =>
  import("./Pages/UserPage/StockTransfer/ITMoutward")
);
const ITMinward = lazy(() =>
  import("./Pages/UserPage/StockTransfer/ITMinward")
);
const LocalSaleBill = lazy(() =>
  import("./Pages/UserPage/Invoice/LocalSaleBill")
);
const ExportInvoice = lazy(() =>
  import("./Pages/UserPage/Invoice/ExportInvoice")
);
const ReceiptPaymentVoucher = lazy(() =>
  import("./Pages/UserPage/Accounts/ReceiptPaymentVoucher")
);
const JournalVoucher = lazy(() =>
  import("./Pages/UserPage/Accounts/JournalVoucher")
);
const InvoicePackingSlipReprint = lazy(() =>
  import("./Pages/UserPage/Reports/InvoicePackingSlipReprint")
);
const Discount = lazy(() => import("./Pages/UserPage/Invoice/Discount"));
const FreightIns = lazy(() => import("./Pages/UserPage/Invoice/FreightIns"));
const BillExport = lazy(() => import("./Pages/UserPage/Invoice/BillExport"));
const DyeChemPurReg = lazy(() =>
  import("./Pages/UserPage/Reports/DyeChemPurReg")
);
const FabPurReg = lazy(() => import("./Pages/UserPage/Reports/FabPurReg"));
const ITMReg = lazy(() => import("./Pages/UserPage/Reports/ITMReg"));
const SamplePurchaseGrnReg = lazy(() =>
  import("./Pages/UserPage/Reports/SamplePurchaseGrnReg")
);
const CutIntoPiecesInWidth = lazy(() =>
  import("./Pages/UserPage/Process/CutIntoPiecesInWidth")
);
const FabricUnprocessed = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/FabricUnprocessed")
);
const FabricAdjustment = lazy(() =>
  import("./Pages/UserPage/Process/FabricAdjustment")
);
const App = () => {
  const token = localStorage.getItem("jjtoken");
  const [open, setOpen] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isAdminAuth, setIsAdminAuth] = useState(false);
  const [isdesignerAuth, setIsdesignerAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function checkAuthStatus() {
    try {
      const checkapicall = await fetch("/api/v1/verify", {
        method: "GET",
        headers: {
          jjtoken: token,
        },
      });
      const response = await checkapicall.json();
      if (response.status && response.payload.roleid === "U") {
        setIsAuth(true);
      } else if (response.status && response.payload.roleid === "A") {
        setIsAdminAuth(true);
      } else if (response.status && response.payload.roleid === "D") {
        setIsdesignerAuth(true);
      } else {
        <Redirect to="/" />;
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    checkAuthStatus();
  }, []);
  return (
    <>
      {isLoading && <Loadingscreen />}
      {!isLoading && !isAuth && !isAdminAuth && !isdesignerAuth && (
        <Login
          setIsAuth={setIsAuth}
          setIsAdminAuth={setIsAdminAuth}
          setIsdesignerAuth={setIsdesignerAuth}
        />
      )}
      {!isLoading && isAdminAuth && (
        <>
          <Navbar
            open={open}
            setIsAdminAuth={setIsAdminAuth}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/adminhome"
                render={(props) =>
                  isAdminAuth ? (
                    <AdminHome {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/userlist"
                render={(props) =>
                  isAdminAuth ? (
                    <UserList {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/usercreation"
                render={(props) =>
                  isAdminAuth ? (
                    <UserCreation {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/editusers/:uid"
                render={(props) =>
                  isAdminAuth ? (
                    <EditUsers {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
      {!isLoading && isAuth && (
        <>
          <UserNav
            setIsAuth={setIsAuth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          {/* <NewUserNav /> */}
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/home"
                render={(props) =>
                  isAuth ? (
                    <UserHome {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/item"
                render={(props) =>
                  isAuth ? (
                    <ItemMaster {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/mainledger"
                render={(props) =>
                  isAuth ? (
                    <Mainledger {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/subledger"
                render={(props) =>
                  isAuth ? (
                    <Subledger {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/quality"
                render={(props) =>
                  isAuth ? (
                    <QualityMaster {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/sampleprint"
                render={(props) =>
                  isAuth ? (
                    <SamplePrint {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/ocentry"
                render={(props) =>
                  isAuth ? (
                    <OcEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/revision"
                render={(props) =>
                  isAuth ? (
                    <Revision {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/yarnpurchase"
                render={(props) =>
                  isAuth ? (
                    <YarnPurchaseEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fabpurchase"
                render={(props) =>
                  isAuth ? (
                    <FabricPurchaseEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/grn"
                render={(props) =>
                  isAuth ? (
                    <Grn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/jobberorder"
                render={(props) =>
                  isAuth ? (
                    <JobberOrd {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/profinvc"
                render={(props) =>
                  isAuth ? (
                    <ProformaInvoice {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/courier"
                render={(props) =>
                  isAuth ? (
                    <CourierInvoice {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/samplepur"
                render={(props) =>
                  isAuth ? (
                    <SamplePur {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechempur"
                render={(props) =>
                  isAuth ? (
                    <DyeChemiclePur {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechemgrn"
                render={(props) =>
                  isAuth ? (
                    <DyeChemGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechemissue"
                render={(props) =>
                  isAuth ? (
                    <DyeChemIssue {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechemrtn"
                render={(props) =>
                  isAuth ? (
                    <DyeChemReturn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechemlot"
                render={(props) =>
                  isAuth ? (
                    <DyeChemRecipie {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/yarngrn"
                render={(props) =>
                  isAuth ? (
                    <YarnGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/samplegrn"
                render={(props) =>
                  isAuth ? (
                    <SampleGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/cut"
                render={(props) =>
                  isAuth ? (
                    <CutIntoPieces {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fabprocess"
                render={(props) =>
                  isAuth ? (
                    <FabricProcessOrder {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fprocessinward"
                render={(props) =>
                  isAuth ? (
                    <FabricProcessInward {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/qcc"
                render={(props) =>
                  isAuth ? (
                    <QualityControl {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fold"
                render={(props) =>
                  isAuth ? (
                    <Folding {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/jobout"
                render={(props) =>
                  isAuth ? (
                    <JobberOutWard {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/jobgrn"
                render={(props) =>
                  isAuth ? (
                    <JobberGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/itmout"
                render={(props) =>
                  isAuth ? (
                    <ITMoutward {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/itmin"
                render={(props) =>
                  isAuth ? (
                    <ITMinward {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/lsalebill"
                render={(props) =>
                  isAuth ? (
                    <LocalSaleBill {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/exportin"
                render={(props) =>
                  isAuth ? (
                    <ExportInvoice {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/rpvoucher"
                render={(props) =>
                  isAuth ? (
                    <ReceiptPaymentVoucher {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/jvoucher"
                render={(props) =>
                  isAuth ? (
                    <JournalVoucher {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/invpslip"
                render={(props) =>
                  isAuth ? (
                    <InvoicePackingSlipReprint {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/frtins"
                render={(props) =>
                  isAuth ? (
                    <FreightIns {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/discount"
                render={(props) =>
                  isAuth ? (
                    <Discount {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/be"
                render={(props) =>
                  isAuth ? (
                    <BillExport {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              {/* reports */}
              <Route
                exact
                path="/dyechempurreg"
                render={(props) =>
                  isAuth ? (
                    <DyeChemPurReg {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fabpurreg"
                render={(props) =>
                  isAuth ? (
                    <FabPurReg {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/itmreg"
                render={(props) =>
                  isAuth ? (
                    <ITMReg {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/samplepurgrnreg"
                render={(props) =>
                  isAuth ? (
                    <SamplePurchaseGrnReg {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/cutpieceinwidth"
                render={(props) =>
                  isAuth ? (
                    <CutIntoPiecesInWidth {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fUnprocessed"
                render={(props) =>
                  isAuth ? (
                    <FabricUnprocessed {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fAdjustment"
                render={(props) =>
                  isAuth ? (
                    <FabricAdjustment {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
      {!isLoading && isdesignerAuth && (
        <>
          <DesignerAppBar setIsdesignerAuth={setIsdesignerAuth} />
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/designerhome"
                render={(props) =>
                  isdesignerAuth ? (
                    <DesignerHome {...props} open={open} />
                  ) : (
                    <Redirect to="/designerhome" />
                  )
                }
              />
              <Route
                exact
                path="/disgneruploadimg"
                render={(props) =>
                  isdesignerAuth ? (
                    <UploadImage {...props} open={open} />
                  ) : (
                    <Redirect to="/designerhome" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  );
};

export default App;
